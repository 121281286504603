.mail-card {
  width: 50%;
  &--content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 70px 40px 30px 40px;
    background-color: $pagesBackColor;
    &--close-btn {
      position: absolute;
      top: 2%;
      right: 2%;
      cursor: pointer;
    }
    &--select-area {
      display: flex;
      gap: 20px;
      .css-b62m3t-container {
        width: 90%;
      }
      &.h-400 {
        height: 300px;
      }
    }
    &--button {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 40px !important;
      border: none;
      &:disabled {
        background-color: lightgrey;
        cursor: default;
      }

      svg polygon,
      line {
        color: $backgroundColor !important;
      }
    }
  }
}
