.modalCreateTarget{
    .company-modal{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f8fbff;
        max-width: min-content;
        margin: auto;
        max-height: min-content;
        border-radius: 5px;
        overflow-y: auto;
        &--container {
            padding: 30px 5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            position: relative;
            &--head {
                width: 594px;
                @include desktop {
                    width: 520px;
                }
            }
            &--input-area {
                width: 100%;
                min-width: 448px;
                display: flex; 
                flex-direction: column;
                @include desktop{
                    min-width: 400px;
                }
                .css-1s2u09g-control{
                    height: 51px;
                }
                .css-1pahdxg-control {
                    height: 51px;
                }
                .react-dropdown-div {
                    margin: 8px 0 0;
                }
                &--input {
                    width: 100%;
                    margin: 8px 0 0;
                    min-width: 40%;
                    padding: 16px;
                    border-radius: 4px;
                    background-color: #fff;
                    border: none;
                    font-size: 16px;
                    &::placeholder {
                        color: #c5c5c5;
                    }
                    &:disabled {
                        background-color: hsl(0, 0%, 95%);
                    }
                }
                &--drag-drop {
                    margin-top: 12px;
                    padding: 16px 0;
                    border-radius: 8px;
                    border: dashed 2px #e1e1e1;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1;
                    &.active {
                        opacity: 0.6;
                    }
                    &--success-area {
                        display: flex;
                        align-items: center;
                        span {
                            color: #44cc62;
                            margin-left: 6px;
                        }
                    }
                    &--logo {
                        width: 78px;
                        height: 58px;
                        object-fit: contain;
                    }
                    &--text-area {
                        font-size: 14px;
                        line-height: 1.14;
                        color: #343248;
                        &--upload-label {
                            cursor: pointer;
                            font-weight: bold;
                            color: #1392e5;
                        }
                    }
                }
            }
            &--input-area-rows {
                width: 100%;
                min-width: 448px;
                display: flex;
                flex-direction: column;
                gap: 24px;
                @include desktop {
                    min-width: 400px;
                }
               &--row {
                .css-1s2u09g-control{
                    height: 51px;
                    .css-1rhbuit-multiValue {
                        .css-12jo7m5 {
                            font-size: inherit;
                        }
                        .css-xb97g8 {
                            svg {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
                .css-1pahdxg-control {
                    height: 51px;
                    .css-1rhbuit-multiValue {
                        .css-12jo7m5 {
                            font-size: inherit;
                        }
                        .css-xb97g8 {
                            svg {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
                .react-dropdown-div {
                    margin: 8px 0 0;
                }
                &--input {
                    width: 100%;
                    margin: 8px 0 0;
                    min-width: 40%;
                    padding: 16px;
                    border-radius: 4px;
                    background-color: #fff;
                    border: none;
                    font-size: 16px;
                    &::placeholder {
                        color: #c5c5c5;
                    }
                }
               }
            }
            &--body {
                display: flex;
                flex-direction: column;
                gap: 24px;
                &--half-width {
                    display: flex;
                    gap: 32px;
                }
            }
            &--footer {
                width: 100%;
                display: flex;
                justify-content: center;
            }
            &--error {
                color: #ff2d55;
                padding: 8px;
            }
            &--close-btn {
                position: absolute;
                top: 2%;
                right: 2%;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                img {
                    width: 30px;
                }
                div{
                    font-size: 12px;
                    margin-top: 4px;
                    font-weight: bold;
                }
            }
        }
    }
}
