@import '../../variables/variables';


%align-center-justıfy-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.wrapper {
    max-width: 95%;
    margin: 0 auto;
    //background-color: $backgroundColor;
  }

  .wrapper-openBox{
    height: 56px;
    padding: 10px;
    background-color: $backgroundColor;
    border-radius: 5px;
    box-shadow: 1px 1 px 2px solid gray;
    margin-bottom: 15px;
    @extend %align-center-justıfy-start;
    &-row{
      width: 65%;
      margin: auto;
      gap: 10% !important;
    }
    div{
      @extend %align-center-justıfy-start
    }
  }

  .accordion-wrapper {
    background-color:  $backgroundColor;
    box-shadow: 1px 1px 4px rgb(170 170 170);
    padding: 5px 10px;
    & + * {
      margin-top: 0.5em;
    }
  }

  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .accordion-title {
    font-weight: bold;
    cursor: pointer;
    gap: 15%;
    font-size: $xlFont;
    color: $passiveTextColor;
    padding: 0.5em 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-mid {
      display: flex;
      gap: 25%;
      flex: 1;
    }
    &-afterText {
      font-weight: normal;
      font-size: $smallFont;
    }

    div{
        display: flex;
        align-items: center;
    }

    img{
        margin-right: 10px;
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid currentColor;
    }

    &:hover,
    &.open {
      color: black;
    }

    &.open {
      &::after {
        content: "";
        border-top: 0;
        border-bottom: 5px solid;
      }
    }
  }

  .accordion-content {
    background-color: $autoSelect;
    padding: 1em 1.5em;
  }