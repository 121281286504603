.page-wrapper {
    padding: 32px 24px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .page-header {
        font-family: "Cabin", sans-serif;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
        color: #1392e5;
    }
    .welcome-title-area {
        width: 100%;
        padding: 24px;
        border-radius: 4px;
        background-color: #f8fbff;
        display: flex;
        flex-direction: column;
        gap: 16px;
        &--title {
            height: 24px;
            font-size: 28px;
            font-weight: bold;
            line-height: 0.86;
            color: #343248;
            &--red {
                height: 24px;
                font-size: 28px;
                font-weight: bold;
                color: #f9423a;
            }
        }
        &--text {
            height: 24px;
            font-size: 22px;
            line-height: 1.09;
            color: #343248;
        }
    }
    .welcome-anouncement-boxes {
        display: flex;
        gap: 24px;
        height: 564px;
        border-radius: 4px;
        flex-wrap: wrap;
        &--title {
            height: 24px;
            font-size: 28px;
            font-weight: bold;
            line-height: 0.86;
            color: #343248;
        }
        &--large {
            min-width: 65%;
            padding: 24px;
            @include tablet {
                min-width: unset;
                width: 100%;
            }
            border-radius: 4px;
            background-color: #f8fbff;
        }
        &--small {
            padding: 24px;
            border-radius: 4px;
            flex: 1;
            background-color: #f8fbff;
        }
    }
}