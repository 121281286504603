.modalDeleteTarget{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1000000000000;
    display: flex;
    align-items: center;
    justify-content: center;

    &-closeBtn{
        position: absolute;
        top: 28%;
        right: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        z-index: 100000000 ;       
        div{
            margin-top: 4px;
            font-weight: bold;
        }
    }


    &-box{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
       background-color: $pagesBackColor;
        max-width: 650px;
        width: 100%;
        margin: auto;
        max-height: 350px;
        height: 100%;
        border-radius: 5px;
        overflow-y: auto;
    }
    &-box-btn{
        width: 100%;
        z-index: 1;
        bottom:230px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-box-close{
        display: flex;
        align-items: center;
        justify-content: center;
        margin:15px 5px;
        padding: 10px 5px;
        cursor: pointer;

        &-h1{
            font-size: 30px;
            font-weight: bold;
        }
    }

    &-box-kpi{
      max-width: 97%;
      margin: auto;
  
       &-head{
        margin-left: 7px;
        font-weight: bold;
        font-size: $smallFont;
       }
    }
    &-box-kpi-sub{
      margin: 5px;
      margin-top: 30px;

      &-header-two{
        display: flex;
        justify-content: center;
        text-align: center;

        div{
            width: 70px; 
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $xsFont; 
            font-weight: bold;
          }
      }
      &-header{
        display: flex;
        justify-content: space-between;
        text-align: center;
             
        div{
          width: 70px; 
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $xsFont; 
          font-weight: bold;
        }
       }
       &-bottom{
        display: flex;
        justify-content: space-between;
       }
       &-bottom-two{
        display: flex;
        justify-content: center;
       }
      }
}