.react-dropdown-div{
    max-width: 600px;
    width: 100%;
    border: none;
    margin: 5px;
    &.w-100 {
        max-width: unset;
        .css-1s2u09g-control{
            height: fit-content !important;  
        }
        .css-1pahdxg-control{     
            height: fit-content !important;  
        }
    }

    .css-1s2u09g-control{
        border: none;
        box-shadow: none;
        border-color: black;     
        height: 30px;  
    }
    .css-1pahdxg-control{     
        height: 30px; 
        border: none;
        box-shadow: none;
    }
    
     .css-1okebmr-indicatorSeparator{
         display: none;
     }
    

    svg { 
        border: 1px solid #f9423a;
        border-radius: 50%; 
        width: 24px;
        height: 24px;
        path {
            color: #f9423a !important;
        }
}


@include tablet {
    max-width: 350px;
    width: 100%;
  }
}