@import '../../variables/variables';

/* div */
.search-box {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    //transform: translate(-50%, -50%);
    background: transparent;
    height: 42px;
    border: 1px solid $lightBorder;
    border-radius: 50px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px;
    position: relative;  //new

   

    img{
        padding: 10px;
    }
  }
  /* input */
  .search-input {
    outline: none;
    border: none;
    background: white;
    width: 247px;
    padding: 0;
    float: left;
    font-size: 16px;
    transition: .3s;
    line-height: 40px;

    &::placeholder {
        opacity: 1;
        font-size: 14px;
    }

    @include tablet {
      width: 150px;
    }  

    @include theMiddleTabletMobile {
      width: 30px;

      &::placeholder {
        opacity: 0;
        font-size: 14px;
    }
    }
  }
  
  /* icon */
  .search-btn {
    color: #fff;
    float: right;
    width: 58px;
    height: 34px;
    border-radius: 21px;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: .3s;
    cursor: pointer;

   
  }
  .search-input:focus,
  .search-input:not(:placeholder-shown) {
    width: 500px;
    padding: 0 6px;

    @include tablet {
      width: 150px;
    }

    @include theMiddleTabletMobile {
      width: 100px;
    }
  }
//   .search-box:hover > .search-input {
//     width: 240px;
//     padding: 0 6px;
//   }
//   .search-box:hover > .search-btn,
  .search-input:focus + .search-btn,
  .search-input:not(:placeholder-shown) + .search-btn {
    background: $activeBtnColor;
    letter-spacing: 1px;
  }

  .search-input:focus {
    &::placeholder {
        opacity: 0;
    }
  }