.modalCreateTarget {
  .user-modal {
    width: 30%;
    height: 30%;
    min-height: 300px;
    background-color: #f8fbff;
    &--container {
      height: 100%;
      padding: 30px 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      position: relative;
      .css-b62m3t-container {
        width: 80%;
        margin: auto;
      }
      .css-3iigni-container {
        width: 80%;
        margin: auto;
        .css-14el2xx-placeholder {
          color: #fff;
        }
        svg {
          border-color: #fff;
          path {
            color: #fff !important;
          }
        }
      }
      &--close-btn {
        position: absolute;
        top: 2%;
        right: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        img {
          width: 30px;
        }
        div {
          font-size: 12px;
          margin-top: 4px;
          font-weight: bold;
        }
      }
      &--buttons {
        display: flex;
        width: 60%;
      }
      &--checkbox {
        display: flex;
        gap: 10px;
        align-items: center;

      }
    }
  }
}
