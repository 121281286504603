.company-create-target{

     table td {
       max-width: 100%;
      }
  

    &-header{
     display: flex;
     margin: 1px 10px;
     margin-top: 20px;
     font-weight: bold;
     
       
     &-name{
          max-width: 254px;  //tablo durumuna göre koydum.İleride degisim gerekirse ayarlanacak
          width: 100%;
          font-size: $xsFont;
        }
        
     &-year{
          max-width: 100px;
          width: 100%;
          font-size: $xsFont;
        }
      &-statu {
          max-width: 100px;
          width: 100%;
          font-size: $xsFont;
      }
    }

    &-body{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;


        &-right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        }
    }
}