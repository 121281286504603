.table-loading{
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div{
        margin-bottom: 20px;
        font-weight: bold;
        letter-spacing: 1.5px;
    }
}
.sc-ivTmOn{
    background-color: $pagesBackColor !important;
}