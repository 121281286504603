.approval-target {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    background-color: $pagesBackColor;
    &--title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }
    &--list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 80%;
        margin: auto;
        .custom-checkbox {
            width: fit-content;
            cursor: pointer;
        }
    }
}