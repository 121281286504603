@import '../variables/variables';

@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: $passiveTextColor;
    font-size: $mediumFont;
    font-family: 'Cabin', sans-serif;
 }

 .app{
     background-color: $pagesBackColor;
 }

 /* width */
*::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  *::-webkit-scrollbar-track {
    background: $pagesBackColor; 
  }
   
  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: $activeBtnColor; 
    border-radius: 10px;
  }
  
  // /* Handle on hover */
  // *::-webkit-scrollbar-thumb:hover {
  //   background: $activeBtnColor; 
  // }

  input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

  -webkit-appearance: none;

}
.justify-content-start {
  justify-content: flex-start !important;
}
.p-0 {
  padding: 0px !important;
}
.flex-1 {
  flex: 1;
}
.flex-column{
  flex-direction: column;
  gap: 8px !important;
}