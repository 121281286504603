
.company-card-management-page{
   
    max-width:95%;
    margin:auto;
    position: relative;
 
    .rdt_TableBody{
       background-color: $pagesBackColor;
       padding-right: 10px;
    }
 
    .sc-jqUVSM{
      margin: 7px 5px;
      border-bottom-width: 0px !important;
      border-radius: 15px;
      box-shadow: 1px 1px 5px $generalGray;  
 
      &:hover{
       background-color: transparent;
      }
    }
 
    .createRowBtn{
      .iconBtn{
          position: absolute;
          top: 5px;
          right: 5px;
          height: 30px !important;
      }
    }
    .data-table-extensions{
      width: unset !important;
      margin-top: 10px;
    }
 }
 
 // data table style işlemleri:
 .data-table-extensions > .data-table-extensions-action {
    display: none;
 }

 .TpgDh .rdt_TableHeadRow{
    background-color: rgb(69, 69, 69);
    box-shadow: 1px 1px 5px $generalGray;
    border-radius: 15px;
    margin: 7px 5px;
 
 }
 .TpgDh .sc-evZas{
    color: white !important;
 }
 .SelectStyle {

   margin: 5px;
   display: flex;
   z-Index: 998;
   .css-b62m3t-container{
    width: 150px !important;
   }
   .css-3iigni-container {
    width: 150px !important;
   }
   .css-bfro0a-Control {
    width: unset !important;
   }
 }
 .SelectContainer {
  margin-top: 10px;
   display: flex;
   align-items: center;
   z-Index: 2000;
   float: right;


   &-cardCreate{
    margin-right: 10px;
    margin-left: 7px;
   }
 }