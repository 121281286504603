.sirket-kartlari {
    &--inputbox {
        display: flex;
        flex-direction: column;
        width: 100%;
        &--label {
            font-size: 13px;
            font-weight: bold;
            line-height: 1.23;
            color: #343248;
        }
        &--input {
            width: 100%;
            border: none;
            margin: 8px 0 0;
            padding: 16px;
            border-radius: 4px;
            background-color: #fff;
        }
    }
}

.custom-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: "Cabin", sans-serif;
    font-size: 16px;
    font-weight: bold;
    &.disabled {
        opacity: .5;
    }
    input[type='checkbox'] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
    &--checkbox {
        display: inline-block;
        height: 16px;
        width: 16px;
        background: #fff;
        border-radius: 4px !important;
        border: 2px #ddd solid;

        cursor: pointer;
        &.checked {
            border-color: #f9423a;
            background: #f9423a;
        }
    }
}

.quill {
    width: 100%;
    .ql-toolbar{
        background-color: #fff;
    }
    .ql-container{
        background-color: #fff;
    }
}