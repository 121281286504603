@import '../../variables/variables';

.notification-menu{
position: relative;
margin: 10px;
&-icon {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-img{
        height: 60px;
    }
    &-count {
        position: absolute;
        right: 3px;
        top: 3px;
        font-size: 11px;
        background-color: $defaultred;
        color: $backgroundColor;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        text-align: center;
    }
}
// img{
//     border: 1px solid $lightBorder;
//     border-radius: 50%;
//     padding: 9px;
//     margin-top: 5px;
//     cursor: pointer;
// }

.drdown{
    padding-left: 8px;
    //line-height: 23px;
    width: 410px;
    height: 30vh;
    z-index: 99999;
    overflow-x: scroll;
    border: 1px solid $lightBorder;
    background-color: $backgroundColor;
    position: absolute;
    right: 0;
    top: 117%;
    border-radius: 10px;
    z-index: 999;
    &-seeall {
        width: 100%;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: $activeBtnColor;
    }
 }
}

.notification-menu-sub{
    border-left: 4px solid green;
    //border-radius: 10px;
    padding:0px 8px;
    margin: 17px 2px;
    
    &-date {
        opacity: 0.6;
        font-size: $xsFont;
        margin-bottom: 5px;
    }
    &-title {
        font-size: $mediumFont;
        font-weight: bold;
        margin-bottom: 5px;

    }
    &-description {
        font-size: $smallFont;
    }
}
