.create-company-card{
    height: 100%;

    &-header{
        padding: 25px;
        font-weight: bold;
        font-size: $xlFont;
        color: $activeBtnColor ;
        background-color: $backgroundColor;
    }

    &-select{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;

    #react-dropdown-div-id{
     margin: 25px 10px;
     height: 50px;
     background-color: white;
     padding-top: 5px;
       
     div{
        font-size: $largeFont;
        font-weight: bold;
     }
    }
    }
}