

.autocomplete{
    margin: 5px;
    //#E9EEF4

    .css-1s2u09g-control{
        border: none;
        box-shadow: none;
        border-color: black; 
        background-color: $autoSelect; 
    }
    .css-1pahdxg-control{ 
        border: none;
        box-shadow: none;
        background-color: $autoSelect; 

    }
    
     .css-1okebmr-indicatorSeparator{
         display: none;
     }
    
}

