.aim-management-page{
   
    max-width:95%;
    margin:auto;
    position: relative;
 
    .rdt_TableBody{
       background-color: $pagesBackColor;
       padding-right: 10px;
    }
 
    .sc-jqUVSM{
      margin: 7px 5px;
      border-bottom-width: 0px !important;
      border-radius: 15px;
      box-shadow: 1px 1px 5px $generalGray;  
 
      &:hover{
       background-color: transparent;
      }
    }
 
    .createRowBtn{
      .iconBtn{
          position: absolute;
          top: 5px;
          right: 5px;
          height: 30px !important;
      }
    }
 }
 
 // data table style işlemleri:
 .data-table-extensions > .data-table-extensions-action {
    display: none;
 }
 
 .rdt_TableHead .rdt_TableHeadRow{
    background-color: rgb(69, 69, 69) !important;
    box-shadow: 1px 1px 5px $generalGray;
    border-radius: 15px;
    margin: 7px 5px;
 
 }
 .rdt_TableHead .rdt_TableCol_Sortable div{
    color: white !important;
 }