.react-datepicker-wrapper{
    

    .react-datepicker__input-container  input{
        height: 50px;
        max-width: 380px;
        width: 100%;
        font-size: $largeFont;
        font-weight: bold;
        border: 1px solid $lightBorder;
        padding: 10px;
        border-radius: 8px;
    }


    // .react-datepicker-popper{
       // tarih cıkan alan scss alanı
    // }
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll{
    display: flex;
    justify-content: center;
    gap: 20px;
}

#date-calendar{
    height: 50px;
        max-width: 380px;
        width: 100%;
        font-size: $largeFont;
        font-weight: bold;
        border: 1px solid $lightBorder;
        padding: 10px;
        border-radius: 8px;
}