.ceoPagesModal{
    border: 1px solid white;
    padding: 15px;
    border-radius: 10px;
    background-color: white;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1{
        // font-size: $xlFont;
        font-size: 17px;
        font-weight: normal;
        letter-spacing: 0.7px;
    }
    
    &-yes-no{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 13px;
    }
    &-yes-no div{
      margin: 7px;
      border: 1px solid black;
      border-radius: 7px; 
      width: 120px;
      padding: 5px;
      text-align: center;
    }
    &-yes-no div:hover{
        opacity: 0.6;
        cursor: pointer;
      }
}
.cfoapprovalModal {
  position: relative;
  border: 1px solid white;
  padding: 45px 35px 25px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  &--close {
    cursor: pointer;
    position: absolute;
    right: 1%;
    top: 3%;
    width: 32px;
    height: 32px;
  }
  &--text{
    // font-size: $xlFont;
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0.7px;
    &--link {
      color: $activeBtnColor;
      font-size: 17px;
      text-decoration: none;
      font-weight: bold;
    }
  }
}