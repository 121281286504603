@import '../../variables/variables';

.sidebar{
    position: sticky;
    left: 0;
    top: 0px;
    height: 100%;
    background-color: $backgroundColor;
    border-right:1px solid $lightBorder;
    width: 100px !important;
    z-index: 1000;

    &-button{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $lightBorder;
        height: 79px;
        transition: ease-in-out 0.5s;
        text-align: center;
        //font-size: $xsFonts;

        img{
            margin-bottom: 5px;
        }
    }
    &-button:hover{
        background-color: rgb(233, 233, 233);
        cursor: pointer;
    }


     // AÇILABİLİR MENU ALANI
     &-openMenu{
        position: absolute;
        top: 0px;
        left: 99px;
        opacity: 1;
        height: 100%;  
        width: 245px;   //342 - 97 = 245px
        padding: 15px;
        background-color: $backgroundColor !important;
        box-shadow: 2px 0 3px 0 rgba(0, 0, 0, 0.08);
        z-index: 1000;
    }
    &-openMenu-sub{
      display: flex;
      align-items: center;
      padding-top: 15px;
      cursor: pointer;
      text-decoration: none;
      img{
          margin-right: 5px;
      }

    }
}