@import '../../variables/variables';

.messageCard{
    position: relative;
    max-width: 54%;
    padding: 10px;
    width: 100%;
    background-color: $backgroundColor;
    border-radius: 5px;
    box-shadow: 1px 1 px 2px solid gray;
    margin: 20px;

    &-click-iconAttach{ 
        padding: 10px;
        cursor: pointer;
        
        &-preview{
            display: flex;
            align-items: center;
            padding-top: 5px;

            a{
                font-size: $xsFont;
                margin-left: 5px;
            }
        }
    }

    &-reply-message{
        border: 1px solid $generalGray;
        max-width: 75%;
        padding: 10px;
        height: 65px;
        overflow-y: auto;
        background-color: $lightBorder;
        border-radius: 5px;
        margin-bottom: 10px;

        &-top{
          display: flex;
          align-items: center;
          justify-content: space-between;
          span{
            font-size: $xsFont;
            opacity: 0.6;
          }
        }

        &-bottom{
            font-size: $smallFont;
            font-weight: bold;
            margin-top: 7px;
            opacity: 0.8;

            strong{
                color: crimson;
                font-size: $xsFont;
                margin-right: 2px;
            }
        }
    }

    &-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 13px;

        &-btn{
            border: 1px solid $lightBorder;
            padding: 2px 5px;
            border-radius: 5px;
        }
        &-left {
            display: flex;
        }
        &-right{
            display: flex;
            align-items: center;

            div{
                display: flex;
                align-items: center;
            }
        
             div span{
                padding: 2px;
                cursor: pointer;
                opacity: 0.7;
             }
        }

        &-answer{
           color: #ffad00;
           cursor: pointer;

           img{
            width: 18px;
           }
        }

        span{
        font-size: $smallFont;
        display: flex;
        align-items: center;
        }
    }

    &-body{
        margin-bottom: 17px;
    }
    &-date{
        margin-bottom: 13px;
        font-size: $xsFont; 
        color: $generalGray;
    }
    &-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-name {
            color: rgb(198, 14, 51);
            font-size: $mediumFont !important; 
            font-weight: bold;
        }
        span{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: $xsFont; 
            cursor: pointer;
        }
    }
}