.modal{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 100000000;
    display: flex;
    align-items: center;
    justify-content: center;


    &-box{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
       background-color: white;
        max-width: 650px;
        width: 100%;
        margin: auto;
        max-height: 250px;
        height: 100%;
        border-radius: 5px;
    }

    &-box-close{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px;
        padding: 10px 5px;
        cursor: pointer;
        border-bottom: 1px solid gray;
    }
}