@import '../variables/variables';

@mixin mobile {
    @media screen and (max-width: $mobileScreen) {
      @content;
    }
  }

  @mixin theMiddleTabletMobile {
    @media screen and (max-width: $theMiddleTabletMobileScreen) {
      @content;
    }
  }
  
  @mixin tablet {
    @media screen and (max-width: $tabletScreen) {
      @content;
    }
  }  

  @mixin desktop {
    @media screen and (max-width: $desktop) {
      @content;
    }
  }  