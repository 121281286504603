.modalCreateTarget {
    .permission-modal {
      width: 60%;
      min-height: 300px;
      max-width: unset;
      background-color: #f8fbff;
      margin-top: 30px;
      &--container {
        height: 100%;
        padding: 30px 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        gap: 20px;
        .permission-table-wrapper {
          width: 100%;
          max-height: 460px;
          overflow: scroll;
          margin-top: 30px;
          .permission-table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 10px;
            line-height: 35px;
            &--head {
              padding: 10px;
              line-height: 35px;
              background-color: rgb(69, 69, 69);
              box-shadow: 1px 1px 5px rgb(168, 168, 168);
              border-radius: 8px;
              margin: 7px 5px;
                &--row {
                    &--title {
                      color: #fff;
                      &:first-child {
                        padding-left: 10px;
                        text-align: start;
                      }
                    }
                }
            }
            &--body {
              max-height: 150px;
              overflow: scroll;
              &--row {
                margin: 7px 5px;
                border-bottom-width: 0px !important;
                box-shadow: 1px 1px 1px rgb(168, 168, 168);
                &--content {
                  &:first-child{
                    padding-left: 10px;
                  }
                }
              }
            }
        }
        }
        &--close-btn {
          position: absolute;
          top: 2%;
          right: 2%;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          img {
            width: 30px;
          }
          div {
            font-size: 12px;
            margin-top: 4px;
            font-weight: bold;
          }
        }
        &--buttons {
          display: flex;
          width: 60%;
        }
        &--checkbox {
          display: flex;
          gap: 10px;
          align-items: center;
  
        }
      }
    }
  }
  