@import '../../variables/variables';

.footer {
    width:100%;
    height:32px;
    background-color: $passiveTextColor;
    display:flex;
    align-items:center;
    justify-content:center;
    position: absolute;
    bottom: 0;
}