// random deger ve isimler atıldı , update edilecek

$backgroundColor:#ffffff;
$lightBorder: #f1efef;
$activeBtnColor: #1392e5;
$passiveTextColor: #343248;
$notificationColor: #44cc62;
$pagesBackColor:#f8fbff;
$autoSelect:#E9EEF4;
$generalGray: rgb(168, 168, 168);
$defaultred:#f9423a;