

.target {
    margin: 40px 10px;
    border-radius: 10px;
    // box-shadow: 1px 1px 4px $passiveTextColor;
    box-shadow: 1px 1px 4px rgb(170, 170, 170);
    padding:5px 10px;
    background-color: $backgroundColor;

    &-targetKPI-area{
        margin-right: 5px;
        .css-1okebmr-indicatorSeparator{
            display: none;
        }
        .css-tlfecz-indicatorContainer{
            padding-left: 0px;
        }
    }

    &-unit-area{
         margin:0px 5px;
        .css-1pahdxg-control{ 
            border: none;
            box-shadow: none;
            background-color: $autoSelect; 
    
        }
        .css-1s2u09g-control{
             width: 100px !important;
            //width: 100% !important;
            border: none;
            background-color: #E9EEF4; 
            outline: none;
        }

        .css-1okebmr-indicatorSeparator{
            display: none;
        }
        .css-tlfecz-indicatorContainer{
            padding-left: 0px;
        }
    }

    &-noRow{
        height: 130px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $smallFont;
        letter-spacing: 1px;
        font-weight: bold;
    }

    table td{
        text-align: center;
    }

    table tr th{
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .h1{
        font-size: 30px;
        font-weight: bold;
    }

    &-header{
     border-bottom: 1px solid rgb(229, 229, 229);
     padding: 15px 2px;
     display: flex;
     align-items: center;
     justify-content: space-between;
    }

    &-header-right{
     display: flex;
     align-items: center;
     justify-content: flex-end;
     width: 100%;
    }

       
   

    .block { 
         color: #fff; text-transform: uppercase; text-align: center; font-family: Helvetica; 
        position: relative; 
        perspective: 350;
    }
    .block .normal {
        width: 100%;
        padding: 3px; cursor: pointer;
        position:relative; z-index:2;
    }
    .block .hover {
        width: 130px; 
        display: block;
        position:absolute;
        color: #fff;
        text-decoration: none;
        z-index:1;
        transition: all 250ms ease;
        right: -145px;
        top: 0;
    }
    .block:hover .normal {
        background: #fff;
    }
    .block:hover .hover {
        right: -50%;
        z-index: 10;        
        top: -4px;
        transform-origin: top;
    }
    .buttonStyle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    border-radius: 4px;
    padding: 4%;
    }
    .total {
        display: flex;
       justify-content: flex-end;
        font-weight: bold;
       
       }
 
    .totalButton {
        width: 150px !important;
       padding: 0 0 0 0 !important;
       } 
       
    .pStyle{
         margin-top: 15px;
     }
}


// excel export html update area

.excelTr th{
    background-color: #dc3545 !important;
}