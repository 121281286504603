@import '../../variables/variables';


    %align-evenly {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }      

    .profile-menu{
    position: relative;
    cursor: pointer;

    
    &-img{
        width:42px;
        height:42px;
        border-radius:50%;
        background-color: black;
        margin:10px;
        display:flex;
        align-items:center;
        justify-content:center;
        color:white;
        font-size:$largeFont;
    }

    &-dropdown{
        position: absolute;
        right: 0; 
        top: 108%;
        border: 1px solid $lightBorder; 
        width: 107px;
        height: 72px;
        z-index: 10000;
        border-radius: 10px;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white
    }

    &-profile{
        @extend %align-evenly;
        padding: 5px
    }

    &-logout{
        @extend %align-evenly;
        padding: 5px
    }
    }