 @import '../../variables/variables';
 @import './search',
         './profile',
         './notification';

    %align-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }      
     
  .navbar {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
      @extend %align-center;
      padding: 20px;
      height: 66px;
      background-color: $backgroundColor;
      border-bottom: 1px solid $lightBorder;

      &-img {
        height: 66px;
        object-fit: contain;
      }

      .navbar-right{
        @extend %align-center;
      }
  }