.not-found-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--big-title {
        margin-top: 4rem;
        font-size: 16rem;
        font-weight:bolder;
    }
    &--text {
        font-size: 1.5rem;
    }
    &--link {
        width: 150px;
        text-decoration: none;
        margin-top: 2rem;
        text-transform: uppercase;
        &--img {
            transform: rotate(180deg);
            margin-right: 5px;
        }
    }
}