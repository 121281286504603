

.opinions {
    margin: 40px 10px;
    border-radius: 10px;
    // box-shadow: 1px 1px 4px $passiveTextColor;
    box-shadow: 1px 1px 4px rgb(170, 170, 170);
    padding:5px 10px;
    background-color: $backgroundColor;

    .h1{
        font-size: 30px;
        font-weight: bold;
    }


    &-loading-file{
      width: 200px;
      display: flex;
      align-items: center;
      margin-top: 15px;
      font-weight: bold;
      opacity: 0.6;
  
      div{
        margin-left: 7px;
        font-size: $xsFont;
      }
    }


    &-map-file{
      max-width: 350px;
      width: 100%;

      &-close{
       cursor: pointer;
      }

      div{
        display: flex;
        align-items: center !important;
        justify-content: space-between;
        margin: 10px;
        padding: 6px;
        border:1px dashed $lightBorder;
        border-radius: 8px;
        

        strong{
          display: flex;
          align-items: center;
        }
        span{
          margin-left: 12px;
          font-size: $xsFont;
        }
      }
    }

    &-dropdown{
        max-width:175px; 
        width:100%;
        height:50px;
        border:1px solid $passiveTextColor;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius: 5px;
    }
    &-multidropdown {
      width:fit-content;
      min-width: 175px;
      height:50px;
      border:1px solid $passiveTextColor;
      display:flex;
      align-items:center;
      justify-content:center;
      border-radius: 5px;
    }
    &-header{
        border-bottom: 1px solid rgb(231, 223, 223);
        padding: 15px 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
       }

    &-container{
     background-color: $autoSelect;
     padding: 10px;
     border-radius: 10px;
     margin-top: 10px;
     box-shadow: 1px 1px 4px rgb(170, 170, 170);
     height: 370px;
     overflow-y: auto;
     &-nomessage {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
     }

       &-right{
        display: flex;
        justify-content: flex-end;
       }
    }

    &-sender{
     padding: 20px;
     max-width: 80%;
     margin: auto;
     
     &-input{
      position: relative;
     }

     &-input textarea{
       width: 100%;
       height: 300px;
       margin-top: 10px;
       padding: 10px;
       background-color: $autoSelect;
       border: 1px solid $generalGray;
       border-radius: 5px;
       outline: none;
     }

     &-fileupload{
      display: flex;
      align-items: center;
      margin-top: 10px;
      cursor: pointer;

      label{
        margin-left: 3px;
        text-decoration: underline;
        font-weight: bold;
      }
     }

      &-btn{
        position: absolute;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px !important;
        margin: auto;
        color: $backgroundColor;
        background-color: $passiveTextColor;
        padding: 10px 15px;
        border:none;
        border-radius: 5px;
        cursor: pointer;
        &:disabled {
          background-color: lightgrey;
          cursor:default
        }

        svg polygon,line{
           color: $backgroundColor !important;
          }
      }
    }
    

}