.modalCreateTarget {
    .excel-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f8fbff;
        max-width: 400px;
        margin: auto;
        max-height: 400px;
        border-radius: 5px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &--close {
            position: absolute;
            top: 2%;
            right: 2%;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
        }
       &--container {
        &--upload-area {
            width: 300px;
            height: 80px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0px 6px;
            &--text {
                font-size: 14px;
                line-height: 1.14;
                color: #343248;
                text-align: center;
                &--upload-label {
                    cursor: pointer;
                    font-weight: bold;
                    color: #1392e5;
                }
            }
            &--file {
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                &--upload {
                    background-color: transparent;
                    border-radius: 4px;
                    border: 1px solid #44cc62;
                    width: 80px;
                    height: 30px;
                    cursor: pointer;
                }
                &--cancel {
                    background-color: transparent;
                    border-radius: 4px;
                    border: 1px solid #f9423a;
                    width: 80px;
                    height: 30px;
                    cursor: pointer;
                }
            }
        }
        &--select-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            width: 80%;
            margin: auto;
            &--inputs {
                display: flex;
                justify-content: space-between;
                &--input {
                    width: 45%;
                    input {
                        width: 100%;
                        height: 35px;
                        border-radius: 4px;
                        border: solid 2px #e1e1e1;
                        width: 100%;
                        height: 35px;
                        margin: 8px 0 0;
                        padding: 16px;
                        background-color: #fff;
                        font-size: 16px;
                        text-transform: uppercase;
                        &::placeholder {
                            color: #c5c5c5;
                        }
                    }
                }
            }
            .css-b62m3t-container {
                width: 100%;
            }
        }
       }
    }
}