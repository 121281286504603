

.target-bonus {
    margin: 40px 10px;
    border-radius: 10px;
     // box-shadow: 1px 1px 4px $passiveTextColor;
     box-shadow: 1px 1px 4px rgb(170, 170, 170);
    padding:5px 10px;
    background-color: $backgroundColor;

    &-noRow{
        height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $smallFont;
        font-weight: bold;
        letter-spacing: 1px;
        
    }

    .h1{
        font-size: 30px;
        font-weight: bold;
    }
    table tr th{
        padding-top: 20px;
        padding-bottom: 10px;
    }
    table td{
       padding: 5px;
    }

    &-header{
     border-bottom: 1px solid rgb(229, 229, 229);
     padding: 15px 2px;
     display: flex;
     align-items: center;
     justify-content: space-between;
    }

    &-header-right{
     display: flex;
     align-items: center;
     justify-content: flex-end;
     width: 100%;
    }


    .target-bonus-body{
     
        .target-body-head {
            max-width: 650px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            text-align: center;
            margin-top:20px;
            margin-bottom: 6px;
            font-weight: bold;


            &-one{
               // max-width: 230px !important;
                width: 370px !important;
                text-align: left;
                padding-left: 7px;
            }


            &-two{  
                width: 70px ;
                text-align: center;
            }
        }

        &-row-bonus{
            max-width: 650px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .block { 
        color: #fff; text-transform: uppercase; text-align: center; font-family: Helvetica; 
       position: relative; 
       perspective: 350;
   }
   .block .normal {
       width: 100%;
       padding: 3px; cursor: pointer;
       position:relative; z-index:2;
   }
   .block .hover {
       width: 130px;
       display: block;
       position:absolute;
       text-decoration: none;
       z-index:1;
       transition: all 250ms ease;
       right: -145px;
       top: 0;
   }
  
   .block:hover .hover {
       right: -6%;
       z-index: 10;
       top:-5px;
       transform-origin: top;
   }
   .buttonStyle {
   color: #fff;
   background-color: #dc3545;
   border-color: #dc3545;
   border-radius: 4px;
   padding: 4%;
   }
   .total {
    display: flex;
    font-weight: bold;
    flex-direction: column;
    align-items: end;
   
   }

.totalButton {
   padding: 0 0 0 0 !important;
   display: flex;
   } 
   
.pStyle{
     margin-top: 15px;
 }
}