//changed button 

.changed-button{
    margin: 3px !important;
    &--button {
        width: 100%;
        height: 38px;
        background-color: #E9EEF4;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: underline;
        border: none;
        &-sm {
            width: 70px;
            height: 38px;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid lightgray;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    input{
        //max-width:70px ;
        border: 1px solid $notificationColor;
        min-width: 60px;
        width: 100%;
        background-color: $autoSelect;
        border-radius: 5px;
        height: 38px;
        text-align: center;
        outline: none;
        padding-left: 5px;
        &:disabled {
            border: none;
        }
        &:focus{
            border: 1px solid #FFAD00;
        }
    }
}
.data-table-delete-btn{
    background-color: #f9423a;
    &.ms-1 {
        margin-left: 10px;
    }
}
.excel-button {
    img {
        @include desktop {
            display: none;
        }
    }
    span {
        @include desktop {
            font-size: small;
        }
    }
}