.default-progress{
    align-items: center;
    justify-content: center;
    .MuiCircularProgress-colorPrimary {
        svg {
            circle {
                color: #f9423a;
            }
        }
    }
}

.opinions-sender-input{
    .MuiCircularProgress-colorPrimary {
        svg {
            circle {
                color: white;
            }
        }
    }
}
