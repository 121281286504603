@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #343248;
  font-size: 14px;
  font-family: "Cabin", sans-serif;
}

.app {
  background-color: #f8fbff;
}

/* width */
*::-webkit-scrollbar {
  width: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f8fbff;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #1392e5;
  border-radius: 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.p-0 {
  padding: 0px !important;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column;
  gap: 8px !important;
}

/* div */
.search-box {
  background: transparent;
  height: 42px;
  border: 1px solid #f1efef;
  border-radius: 50px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px;
  position: relative;
}
.search-box img {
  padding: 10px;
}

/* input */
.search-input {
  outline: none;
  border: none;
  background: white;
  width: 247px;
  padding: 0;
  float: left;
  font-size: 16px;
  transition: 0.3s;
  line-height: 40px;
}
.search-input::-moz-placeholder {
  opacity: 1;
  font-size: 14px;
}
.search-input:-ms-input-placeholder {
  opacity: 1;
  font-size: 14px;
}
.search-input::placeholder {
  opacity: 1;
  font-size: 14px;
}
@media screen and (max-width: 936px) {
  .search-input {
    width: 150px;
  }
}
@media screen and (max-width: 603px) {
  .search-input {
    width: 30px;
  }
  .search-input::-moz-placeholder {
    opacity: 0;
    font-size: 14px;
  }
  .search-input:-ms-input-placeholder {
    opacity: 0;
    font-size: 14px;
  }
  .search-input::placeholder {
    opacity: 0;
    font-size: 14px;
  }
}

/* icon */
.search-btn {
  color: #fff;
  float: right;
  width: 58px;
  height: 34px;
  border-radius: 21px;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
}

.search-input:not(:-moz-placeholder-shown) {
  width: 500px;
  padding: 0 6px;
}

.search-input:not(:-ms-input-placeholder) {
  width: 500px;
  padding: 0 6px;
}

.search-input:focus,
.search-input:not(:placeholder-shown) {
  width: 500px;
  padding: 0 6px;
}
@media screen and (max-width: 936px) {
  .search-input:not(:-moz-placeholder-shown) {
    width: 150px;
  }
  .search-input:not(:-ms-input-placeholder) {
    width: 150px;
  }
  .search-input:focus,
.search-input:not(:placeholder-shown) {
    width: 150px;
  }
}
@media screen and (max-width: 603px) {
  .search-input:not(:-moz-placeholder-shown) {
    width: 100px;
  }
  .search-input:not(:-ms-input-placeholder) {
    width: 100px;
  }
  .search-input:focus,
.search-input:not(:placeholder-shown) {
    width: 100px;
  }
}

.search-input:not(:-moz-placeholder-shown) + .search-btn {
  background: #1392e5;
  letter-spacing: 1px;
}

.search-input:not(:-ms-input-placeholder) + .search-btn {
  background: #1392e5;
  letter-spacing: 1px;
}

.search-input:focus + .search-btn,
.search-input:not(:placeholder-shown) + .search-btn {
  background: #1392e5;
  letter-spacing: 1px;
}

.search-input:focus::-moz-placeholder {
  opacity: 0;
}

.search-input:focus:-ms-input-placeholder {
  opacity: 0;
}

.search-input:focus::placeholder {
  opacity: 0;
}

.profile-menu-logout, .profile-menu-profile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.profile-menu {
  position: relative;
  cursor: pointer;
}
.profile-menu-img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: black;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
}
.profile-menu-dropdown {
  position: absolute;
  right: 0;
  top: 108%;
  border: 1px solid #f1efef;
  width: 107px;
  height: 72px;
  z-index: 10000;
  border-radius: 10px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.profile-menu-profile {
  padding: 5px;
}
.profile-menu-logout {
  padding: 5px;
}

.notification-menu {
  position: relative;
  margin: 10px;
}
.notification-menu-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.notification-menu-icon-img {
  height: 60px;
}
.notification-menu-icon-count {
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 11px;
  background-color: #f9423a;
  color: #ffffff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
}
.notification-menu .drdown {
  padding-left: 8px;
  width: 410px;
  height: 30vh;
  z-index: 99999;
  overflow-x: scroll;
  border: 1px solid #f1efef;
  background-color: #ffffff;
  position: absolute;
  right: 0;
  top: 117%;
  border-radius: 10px;
  z-index: 999;
}
.notification-menu .drdown-seeall {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #1392e5;
}

.notification-menu-sub {
  border-left: 4px solid green;
  padding: 0px 8px;
  margin: 17px 2px;
}
.notification-menu-sub-date {
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 5px;
}
.notification-menu-sub-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
.notification-menu-sub-description {
  font-size: 13px;
}

.navbar .navbar-right, .navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar {
  padding: 20px;
  height: 66px;
  background-color: #ffffff;
  border-bottom: 1px solid #f1efef;
}
.navbar-img {
  height: 66px;
  -o-object-fit: contain;
     object-fit: contain;
}
.sidebar {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: #ffffff;
  border-right: 1px solid #f1efef;
  width: 100px !important;
  z-index: 1000;
}
.sidebar-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f1efef;
  height: 79px;
  transition: ease-in-out 0.5s;
  text-align: center;
}
.sidebar-button img {
  margin-bottom: 5px;
}
.sidebar-button:hover {
  background-color: rgb(233, 233, 233);
  cursor: pointer;
}
.sidebar-openMenu {
  position: absolute;
  top: 0px;
  left: 99px;
  opacity: 1;
  height: 100%;
  width: 245px;
  padding: 15px;
  background-color: #ffffff !important;
  box-shadow: 2px 0 3px 0 rgba(0, 0, 0, 0.08);
  z-index: 1000;
}
.sidebar-openMenu-sub {
  display: flex;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;
  text-decoration: none;
}
.sidebar-openMenu-sub img {
  margin-right: 5px;
}

.wrapper-openBox div, .wrapper-openBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.wrapper {
  max-width: 95%;
  margin: 0 auto;
}

.wrapper-openBox {
  height: 56px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 1px 1 px 2px solid gray;
  margin-bottom: 15px;
}
.wrapper-openBox-row {
  width: 65%;
  margin: auto;
  gap: 10% !important;
}
.accordion-wrapper {
  background-color: #ffffff;
  box-shadow: 1px 1px 4px rgb(170, 170, 170);
  padding: 5px 10px;
}
.accordion-wrapper + * {
  margin-top: 0.5em;
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: bold;
  cursor: pointer;
  gap: 15%;
  font-size: 22px;
  color: #343248;
  padding: 0.5em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion-title-mid {
  display: flex;
  gap: 25%;
  flex: 1;
}
.accordion-title-afterText {
  font-weight: normal;
  font-size: 13px;
}
.accordion-title div {
  display: flex;
  align-items: center;
}
.accordion-title img {
  margin-right: 10px;
}
.accordion-title::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
}
.accordion-title:hover, .accordion-title.open {
  color: black;
}
.accordion-title.open::after {
  content: "";
  border-top: 0;
  border-bottom: 5px solid;
}

.accordion-content {
  background-color: #E9EEF4;
  padding: 1em 1.5em;
}

.react-dropdown-div {
  max-width: 600px;
  width: 100%;
  border: none;
  margin: 5px;
}
.react-dropdown-div.w-100 {
  max-width: unset;
}
.react-dropdown-div.w-100 .css-1s2u09g-control {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.react-dropdown-div.w-100 .css-1pahdxg-control {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.react-dropdown-div .css-1s2u09g-control {
  border: none;
  box-shadow: none;
  border-color: black;
  height: 30px;
}
.react-dropdown-div .css-1pahdxg-control {
  height: 30px;
  border: none;
  box-shadow: none;
}
.react-dropdown-div .css-1okebmr-indicatorSeparator {
  display: none;
}
.react-dropdown-div svg {
  border: 1px solid #f9423a;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.react-dropdown-div svg path {
  color: #f9423a !important;
}
@media screen and (max-width: 936px) {
  .react-dropdown-div {
    max-width: 350px;
    width: 100%;
  }
}

.autocomplete {
  margin: 5px;
}
.autocomplete .css-1s2u09g-control {
  border: none;
  box-shadow: none;
  border-color: black;
  background-color: #E9EEF4;
}
.autocomplete .css-1pahdxg-control {
  border: none;
  box-shadow: none;
  background-color: #E9EEF4;
}
.autocomplete .css-1okebmr-indicatorSeparator {
  display: none;
}

.footer {
  width: 100%;
  height: 32px;
  background-color: #343248;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
}

.modal {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  max-width: 650px;
  width: 100%;
  margin: auto;
  max-height: 250px;
  height: 100%;
  border-radius: 5px;
}
.modal-box-close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  padding: 10px 5px;
  cursor: pointer;
  border-bottom: 1px solid gray;
}

.modalCreateTarget {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalCreateTarget-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f8fbff;
  max-width: 650px;
  width: 100%;
  margin: auto;
  max-height: 350px;
  height: 100%;
  border-radius: 5px;
  overflow-y: auto;
}
.modalCreateTarget-box-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.modalCreateTarget-box-close {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 5px;
  padding: 10px 5px;
  cursor: pointer;
}
.modalCreateTarget-box-close-closeBtn {
  position: absolute;
  top: 2%;
  right: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.modalCreateTarget-box-close-closeBtn img {
  width: 30px;
}
.modalCreateTarget-box-close-closeBtn div {
  font-size: 12px;
  margin-top: 4px;
  font-weight: bold;
}
.modalCreateTarget-box-close-h1 {
  font-size: 30px;
  font-weight: bold;
}
.modalCreateTarget-box-kpi {
  max-width: 97%;
  margin: auto;
}
.modalCreateTarget-box-kpi-head {
  margin-left: 7px;
  font-weight: bold;
  font-size: 13px;
}
.modalCreateTarget-box-kpi-sub {
  margin: 5px;
  margin-top: 10px;
}
.modalCreateTarget-box-kpi-sub-header-two {
  display: flex;
  justify-content: center;
  text-align: center;
}
.modalCreateTarget-box-kpi-sub-header-two div {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}
.modalCreateTarget-box-kpi-sub-header {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.modalCreateTarget-box-kpi-sub-header div {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}
.modalCreateTarget-box-kpi-sub-bottom {
  display: flex;
  justify-content: space-between;
}
.modalCreateTarget-box-kpi-sub-bottom-two {
  display: flex;
  justify-content: center;
}

.modalCreateTarget .company-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f8fbff;
  max-width: -webkit-min-content;
  max-width: -moz-min-content;
  max-width: min-content;
  margin: auto;
  max-height: -webkit-min-content;
  max-height: -moz-min-content;
  max-height: min-content;
  border-radius: 5px;
  overflow-y: auto;
}
.modalCreateTarget .company-modal--container {
  padding: 30px 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: relative;
}
.modalCreateTarget .company-modal--container--head {
  width: 594px;
}
@media screen and (max-width: 1600px) {
  .modalCreateTarget .company-modal--container--head {
    width: 520px;
  }
}
.modalCreateTarget .company-modal--container--input-area {
  width: 100%;
  min-width: 448px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1600px) {
  .modalCreateTarget .company-modal--container--input-area {
    min-width: 400px;
  }
}
.modalCreateTarget .company-modal--container--input-area .css-1s2u09g-control {
  height: 51px;
}
.modalCreateTarget .company-modal--container--input-area .css-1pahdxg-control {
  height: 51px;
}
.modalCreateTarget .company-modal--container--input-area .react-dropdown-div {
  margin: 8px 0 0;
}
.modalCreateTarget .company-modal--container--input-area--input {
  width: 100%;
  margin: 8px 0 0;
  min-width: 40%;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  border: none;
  font-size: 16px;
}
.modalCreateTarget .company-modal--container--input-area--input::-moz-placeholder {
  color: #c5c5c5;
}
.modalCreateTarget .company-modal--container--input-area--input:-ms-input-placeholder {
  color: #c5c5c5;
}
.modalCreateTarget .company-modal--container--input-area--input::placeholder {
  color: #c5c5c5;
}
.modalCreateTarget .company-modal--container--input-area--input:disabled {
  background-color: hsl(0deg, 0%, 95%);
}
.modalCreateTarget .company-modal--container--input-area--drag-drop {
  margin-top: 12px;
  padding: 16px 0;
  border-radius: 8px;
  border: dashed 2px #e1e1e1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.modalCreateTarget .company-modal--container--input-area--drag-drop.active {
  opacity: 0.6;
}
.modalCreateTarget .company-modal--container--input-area--drag-drop--success-area {
  display: flex;
  align-items: center;
}
.modalCreateTarget .company-modal--container--input-area--drag-drop--success-area span {
  color: #44cc62;
  margin-left: 6px;
}
.modalCreateTarget .company-modal--container--input-area--drag-drop--logo {
  width: 78px;
  height: 58px;
  -o-object-fit: contain;
     object-fit: contain;
}
.modalCreateTarget .company-modal--container--input-area--drag-drop--text-area {
  font-size: 14px;
  line-height: 1.14;
  color: #343248;
}
.modalCreateTarget .company-modal--container--input-area--drag-drop--text-area--upload-label {
  cursor: pointer;
  font-weight: bold;
  color: #1392e5;
}
.modalCreateTarget .company-modal--container--input-area-rows {
  width: 100%;
  min-width: 448px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media screen and (max-width: 1600px) {
  .modalCreateTarget .company-modal--container--input-area-rows {
    min-width: 400px;
  }
}
.modalCreateTarget .company-modal--container--input-area-rows--row .css-1s2u09g-control {
  height: 51px;
}
.modalCreateTarget .company-modal--container--input-area-rows--row .css-1s2u09g-control .css-1rhbuit-multiValue .css-12jo7m5 {
  font-size: inherit;
}
.modalCreateTarget .company-modal--container--input-area-rows--row .css-1s2u09g-control .css-1rhbuit-multiValue .css-xb97g8 svg {
  width: 16px;
  height: 16px;
}
.modalCreateTarget .company-modal--container--input-area-rows--row .css-1pahdxg-control {
  height: 51px;
}
.modalCreateTarget .company-modal--container--input-area-rows--row .css-1pahdxg-control .css-1rhbuit-multiValue .css-12jo7m5 {
  font-size: inherit;
}
.modalCreateTarget .company-modal--container--input-area-rows--row .css-1pahdxg-control .css-1rhbuit-multiValue .css-xb97g8 svg {
  width: 16px;
  height: 16px;
}
.modalCreateTarget .company-modal--container--input-area-rows--row .react-dropdown-div {
  margin: 8px 0 0;
}
.modalCreateTarget .company-modal--container--input-area-rows--row--input {
  width: 100%;
  margin: 8px 0 0;
  min-width: 40%;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  border: none;
  font-size: 16px;
}
.modalCreateTarget .company-modal--container--input-area-rows--row--input::-moz-placeholder {
  color: #c5c5c5;
}
.modalCreateTarget .company-modal--container--input-area-rows--row--input:-ms-input-placeholder {
  color: #c5c5c5;
}
.modalCreateTarget .company-modal--container--input-area-rows--row--input::placeholder {
  color: #c5c5c5;
}
.modalCreateTarget .company-modal--container--body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.modalCreateTarget .company-modal--container--body--half-width {
  display: flex;
  gap: 32px;
}
.modalCreateTarget .company-modal--container--footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modalCreateTarget .company-modal--container--error {
  color: #ff2d55;
  padding: 8px;
}
.modalCreateTarget .company-modal--container--close-btn {
  position: absolute;
  top: 2%;
  right: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.modalCreateTarget .company-modal--container--close-btn img {
  width: 30px;
}
.modalCreateTarget .company-modal--container--close-btn div {
  font-size: 12px;
  margin-top: 4px;
  font-weight: bold;
}

.modalCreateTarget .excel-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f8fbff;
  max-width: 400px;
  margin: auto;
  max-height: 400px;
  border-radius: 5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modalCreateTarget .excel-modal--close {
  position: absolute;
  top: 2%;
  right: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.modalCreateTarget .excel-modal--container--upload-area {
  width: 300px;
  height: 80px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
}
.modalCreateTarget .excel-modal--container--upload-area--text {
  font-size: 14px;
  line-height: 1.14;
  color: #343248;
  text-align: center;
}
.modalCreateTarget .excel-modal--container--upload-area--text--upload-label {
  cursor: pointer;
  font-weight: bold;
  color: #1392e5;
}
.modalCreateTarget .excel-modal--container--upload-area--file {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.modalCreateTarget .excel-modal--container--upload-area--file--upload {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #44cc62;
  width: 80px;
  height: 30px;
  cursor: pointer;
}
.modalCreateTarget .excel-modal--container--upload-area--file--cancel {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #f9423a;
  width: 80px;
  height: 30px;
  cursor: pointer;
}
.modalCreateTarget .excel-modal--container--select-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 80%;
  margin: auto;
}
.modalCreateTarget .excel-modal--container--select-area--inputs {
  display: flex;
  justify-content: space-between;
}
.modalCreateTarget .excel-modal--container--select-area--inputs--input {
  width: 45%;
}
.modalCreateTarget .excel-modal--container--select-area--inputs--input input {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  border: solid 2px #e1e1e1;
  width: 100%;
  height: 35px;
  margin: 8px 0 0;
  padding: 16px;
  background-color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}
.modalCreateTarget .excel-modal--container--select-area--inputs--input input::-moz-placeholder {
  color: #c5c5c5;
}
.modalCreateTarget .excel-modal--container--select-area--inputs--input input:-ms-input-placeholder {
  color: #c5c5c5;
}
.modalCreateTarget .excel-modal--container--select-area--inputs--input input::placeholder {
  color: #c5c5c5;
}
.modalCreateTarget .excel-modal--container--select-area .css-b62m3t-container {
  width: 100%;
}

.modalDeleteTarget {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1000000000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalDeleteTarget-closeBtn {
  position: absolute;
  top: 28%;
  right: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 100000000;
}
.modalDeleteTarget-closeBtn div {
  margin-top: 4px;
  font-weight: bold;
}
.modalDeleteTarget-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f8fbff;
  max-width: 650px;
  width: 100%;
  margin: auto;
  max-height: 350px;
  height: 100%;
  border-radius: 5px;
  overflow-y: auto;
}
.modalDeleteTarget-box-btn {
  width: 100%;
  z-index: 1;
  bottom: 230px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalDeleteTarget-box-close {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 5px;
  padding: 10px 5px;
  cursor: pointer;
}
.modalDeleteTarget-box-close-h1 {
  font-size: 30px;
  font-weight: bold;
}
.modalDeleteTarget-box-kpi {
  max-width: 97%;
  margin: auto;
}
.modalDeleteTarget-box-kpi-head {
  margin-left: 7px;
  font-weight: bold;
  font-size: 13px;
}
.modalDeleteTarget-box-kpi-sub {
  margin: 5px;
  margin-top: 30px;
}
.modalDeleteTarget-box-kpi-sub-header-two {
  display: flex;
  justify-content: center;
  text-align: center;
}
.modalDeleteTarget-box-kpi-sub-header-two div {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}
.modalDeleteTarget-box-kpi-sub-header {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.modalDeleteTarget-box-kpi-sub-header div {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}
.modalDeleteTarget-box-kpi-sub-bottom {
  display: flex;
  justify-content: space-between;
}
.modalDeleteTarget-box-kpi-sub-bottom-two {
  display: flex;
  justify-content: center;
}

.ceoPagesModal {
  border: 1px solid white;
  padding: 15px;
  border-radius: 10px;
  background-color: white;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ceoPagesModal h1 {
  font-size: 17px;
  font-weight: normal;
  letter-spacing: 0.7px;
}
.ceoPagesModal-yes-no {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
}
.ceoPagesModal-yes-no div {
  margin: 7px;
  border: 1px solid black;
  border-radius: 7px;
  width: 120px;
  padding: 5px;
  text-align: center;
}
.ceoPagesModal-yes-no div:hover {
  opacity: 0.6;
  cursor: pointer;
}

.cfoapprovalModal {
  position: relative;
  border: 1px solid white;
  padding: 45px 35px 25px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.cfoapprovalModal--close {
  cursor: pointer;
  position: absolute;
  right: 1%;
  top: 3%;
  width: 32px;
  height: 32px;
}
.cfoapprovalModal--text {
  font-size: 17px;
  font-weight: normal;
  letter-spacing: 0.7px;
}
.cfoapprovalModal--text--link {
  color: #1392e5;
  font-size: 17px;
  text-decoration: none;
  font-weight: bold;
}

.changed-button {
  margin: 3px !important;
}
.changed-button--button {
  width: 100%;
  height: 38px;
  background-color: #E9EEF4;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: underline;
  border: none;
}
.changed-button--button-sm {
  width: 70px;
  height: 38px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid lightgray;
  cursor: pointer;
  text-decoration: underline;
}
.changed-button input {
  border: 1px solid #44cc62;
  min-width: 60px;
  width: 100%;
  background-color: #E9EEF4;
  border-radius: 5px;
  height: 38px;
  text-align: center;
  outline: none;
  padding-left: 5px;
}
.changed-button input:disabled {
  border: none;
}
.changed-button input:focus {
  border: 1px solid #FFAD00;
}

.data-table-delete-btn {
  background-color: #f9423a;
}
.data-table-delete-btn.ms-1 {
  margin-left: 10px;
}

@media screen and (max-width: 1600px) {
  .excel-button img {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .excel-button span {
    font-size: small;
  }
}

.opinions {
  margin: 40px 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgb(170, 170, 170);
  padding: 5px 10px;
  background-color: #ffffff;
}
.opinions .h1 {
  font-size: 30px;
  font-weight: bold;
}
.opinions-loading-file {
  width: 200px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-weight: bold;
  opacity: 0.6;
}
.opinions-loading-file div {
  margin-left: 7px;
  font-size: 12px;
}
.opinions-map-file {
  max-width: 350px;
  width: 100%;
}
.opinions-map-file-close {
  cursor: pointer;
}
.opinions-map-file div {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  margin: 10px;
  padding: 6px;
  border: 1px dashed #f1efef;
  border-radius: 8px;
}
.opinions-map-file div strong {
  display: flex;
  align-items: center;
}
.opinions-map-file div span {
  margin-left: 12px;
  font-size: 12px;
}
.opinions-dropdown {
  max-width: 175px;
  width: 100%;
  height: 50px;
  border: 1px solid #343248;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.opinions-multidropdown {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 175px;
  height: 50px;
  border: 1px solid #343248;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.opinions-header {
  border-bottom: 1px solid rgb(231, 223, 223);
  padding: 15px 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.opinions-container {
  background-color: #E9EEF4;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: 1px 1px 4px rgb(170, 170, 170);
  height: 370px;
  overflow-y: auto;
}
.opinions-container-nomessage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.opinions-container-right {
  display: flex;
  justify-content: flex-end;
}
.opinions-sender {
  padding: 20px;
  max-width: 80%;
  margin: auto;
}
.opinions-sender-input {
  position: relative;
}
.opinions-sender-input textarea {
  width: 100%;
  height: 300px;
  margin-top: 10px;
  padding: 10px;
  background-color: #E9EEF4;
  border: 1px solid rgb(168, 168, 168);
  border-radius: 5px;
  outline: none;
}
.opinions-sender-fileupload {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}
.opinions-sender-fileupload label {
  margin-left: 3px;
  text-decoration: underline;
  font-weight: bold;
}
.opinions-sender-btn {
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px !important;
  margin: auto;
  color: #ffffff;
  background-color: #343248;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.opinions-sender-btn:disabled {
  background-color: lightgrey;
  cursor: default;
}
.opinions-sender-btn svg polygon, .opinions-sender-btn line {
  color: #ffffff !important;
}

.reply-message-component {
  border: 1px solid #f1efef;
  padding: 8px;
  border-radius: 5px;
  max-width: 350px;
  width: 100%;
  height: 75px;
  overflow-y: auto;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
  background-color: #f8fbff;
  position: absolute;
  top: 10px;
  margin: 5px;
  cursor: pointer;
}
.reply-message-component-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1efef;
  padding-bottom: 4px;
  margin-bottom: 7px;
}
.reply-message-component-header span {
  font-size: 12px;
}
.reply-message-component-header-icon {
  cursor: pointer;
}
.reply-message-component-body span strong {
  font-size: 12px;
  color: crimson;
}

.target {
  margin: 40px 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgb(170, 170, 170);
  padding: 5px 10px;
  background-color: #ffffff;
}
.target-targetKPI-area {
  margin-right: 5px;
}
.target-targetKPI-area .css-1okebmr-indicatorSeparator {
  display: none;
}
.target-targetKPI-area .css-tlfecz-indicatorContainer {
  padding-left: 0px;
}
.target-unit-area {
  margin: 0px 5px;
}
.target-unit-area .css-1pahdxg-control {
  border: none;
  box-shadow: none;
  background-color: #E9EEF4;
}
.target-unit-area .css-1s2u09g-control {
  width: 100px !important;
  border: none;
  background-color: #E9EEF4;
  outline: none;
}
.target-unit-area .css-1okebmr-indicatorSeparator {
  display: none;
}
.target-unit-area .css-tlfecz-indicatorContainer {
  padding-left: 0px;
}
.target-noRow {
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: bold;
}
.target table td {
  text-align: center;
}
.target table tr th {
  padding-top: 20px;
  padding-bottom: 10px;
}
.target .h1 {
  font-size: 30px;
  font-weight: bold;
}
.target-header {
  border-bottom: 1px solid rgb(229, 229, 229);
  padding: 15px 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.target-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.target .block {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-family: Helvetica;
  position: relative;
  perspective: 350;
}
.target .block .normal {
  width: 100%;
  padding: 3px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.target .block .hover {
  width: 130px;
  display: block;
  position: absolute;
  color: #fff;
  text-decoration: none;
  z-index: 1;
  transition: all 250ms ease;
  right: -145px;
  top: 0;
}
.target .block:hover .normal {
  background: #fff;
}
.target .block:hover .hover {
  right: -50%;
  z-index: 10;
  top: -4px;
  transform-origin: top;
}
.target .buttonStyle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  border-radius: 4px;
  padding: 4%;
}
.target .total {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
}
.target .totalButton {
  width: 150px !important;
  padding: 0 0 0 0 !important;
}
.target .pStyle {
  margin-top: 15px;
}

.excelTr th {
  background-color: #dc3545 !important;
}

.target-bonus {
  margin: 40px 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgb(170, 170, 170);
  padding: 5px 10px;
  background-color: #ffffff;
}
.target-bonus-noRow {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
}
.target-bonus .h1 {
  font-size: 30px;
  font-weight: bold;
}
.target-bonus table tr th {
  padding-top: 20px;
  padding-bottom: 10px;
}
.target-bonus table td {
  padding: 5px;
}
.target-bonus-header {
  border-bottom: 1px solid rgb(229, 229, 229);
  padding: 15px 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.target-bonus-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.target-bonus .target-bonus-body .target-body-head {
  max-width: 650px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 6px;
  font-weight: bold;
}
.target-bonus .target-bonus-body .target-body-head-one {
  width: 370px !important;
  text-align: left;
  padding-left: 7px;
}
.target-bonus .target-bonus-body .target-body-head-two {
  width: 70px;
  text-align: center;
}
.target-bonus .target-bonus-body-row-bonus {
  max-width: 650px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.target-bonus .block {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-family: Helvetica;
  position: relative;
  perspective: 350;
}
.target-bonus .block .normal {
  width: 100%;
  padding: 3px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.target-bonus .block .hover {
  width: 130px;
  display: block;
  position: absolute;
  text-decoration: none;
  z-index: 1;
  transition: all 250ms ease;
  right: -145px;
  top: 0;
}
.target-bonus .block:hover .hover {
  right: -6%;
  z-index: 10;
  top: -5px;
  transform-origin: top;
}
.target-bonus .buttonStyle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  border-radius: 4px;
  padding: 4%;
}
.target-bonus .total {
  display: flex;
  font-weight: bold;
  flex-direction: column;
  align-items: end;
}
.target-bonus .totalButton {
  padding: 0 0 0 0 !important;
  display: flex;
}
.target-bonus .pStyle {
  margin-top: 15px;
}

.messageCard {
  position: relative;
  max-width: 54%;
  padding: 10px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 1px 1 px 2px solid gray;
  margin: 20px;
}
.messageCard-click-iconAttach {
  padding: 10px;
  cursor: pointer;
}
.messageCard-click-iconAttach-preview {
  display: flex;
  align-items: center;
  padding-top: 5px;
}
.messageCard-click-iconAttach-preview a {
  font-size: 12px;
  margin-left: 5px;
}
.messageCard-reply-message {
  border: 1px solid rgb(168, 168, 168);
  max-width: 75%;
  padding: 10px;
  height: 65px;
  overflow-y: auto;
  background-color: #f1efef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.messageCard-reply-message-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messageCard-reply-message-top span {
  font-size: 12px;
  opacity: 0.6;
}
.messageCard-reply-message-bottom {
  font-size: 13px;
  font-weight: bold;
  margin-top: 7px;
  opacity: 0.8;
}
.messageCard-reply-message-bottom strong {
  color: crimson;
  font-size: 12px;
  margin-right: 2px;
}
.messageCard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}
.messageCard-header-btn {
  border: 1px solid #f1efef;
  padding: 2px 5px;
  border-radius: 5px;
}
.messageCard-header-left {
  display: flex;
}
.messageCard-header-right {
  display: flex;
  align-items: center;
}
.messageCard-header-right div {
  display: flex;
  align-items: center;
}
.messageCard-header-right div span {
  padding: 2px;
  cursor: pointer;
  opacity: 0.7;
}
.messageCard-header-answer {
  color: #ffad00;
  cursor: pointer;
}
.messageCard-header-answer img {
  width: 18px;
}
.messageCard-header span {
  font-size: 13px;
  display: flex;
  align-items: center;
}
.messageCard-body {
  margin-bottom: 17px;
}
.messageCard-date {
  margin-bottom: 13px;
  font-size: 12px;
  color: rgb(168, 168, 168);
}
.messageCard-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messageCard-footer-name {
  color: rgb(198, 14, 51);
  font-size: 14px !important;
  font-weight: bold;
}
.messageCard-footer span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  cursor: pointer;
}

.company-create-target table td {
  max-width: 100%;
}
.company-create-target-header {
  display: flex;
  margin: 1px 10px;
  margin-top: 20px;
  font-weight: bold;
}
.company-create-target-header-name {
  max-width: 254px;
  width: 100%;
  font-size: 12px;
}
.company-create-target-header-year {
  max-width: 100px;
  width: 100%;
  font-size: 12px;
}
.company-create-target-header-statu {
  max-width: 100px;
  width: 100%;
  font-size: 12px;
}
.company-create-target-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.company-create-target-body-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.default-progress {
  align-items: center;
  justify-content: center;
}
.default-progress .MuiCircularProgress-colorPrimary svg circle {
  color: #f9423a;
}

.opinions-sender-input .MuiCircularProgress-colorPrimary svg circle {
  color: white;
}

.table-loading {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.table-loading div {
  margin-bottom: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.sc-ivTmOn {
  background-color: #f8fbff !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  height: 50px;
  max-width: 380px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #f1efef;
  padding: 10px;
  border-radius: 8px;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  display: flex;
  justify-content: center;
  gap: 20px;
}

#date-calendar {
  height: 50px;
  max-width: 380px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #f1efef;
  padding: 10px;
  border-radius: 8px;
}

.modalCreateTarget .user-modal {
  width: 30%;
  height: 30%;
  min-height: 300px;
  background-color: #f8fbff;
}
.modalCreateTarget .user-modal--container {
  height: 100%;
  padding: 30px 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.modalCreateTarget .user-modal--container .css-b62m3t-container {
  width: 80%;
  margin: auto;
}
.modalCreateTarget .user-modal--container .css-3iigni-container {
  width: 80%;
  margin: auto;
}
.modalCreateTarget .user-modal--container .css-3iigni-container .css-14el2xx-placeholder {
  color: #fff;
}
.modalCreateTarget .user-modal--container .css-3iigni-container svg {
  border-color: #fff;
}
.modalCreateTarget .user-modal--container .css-3iigni-container svg path {
  color: #fff !important;
}
.modalCreateTarget .user-modal--container--close-btn {
  position: absolute;
  top: 2%;
  right: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.modalCreateTarget .user-modal--container--close-btn img {
  width: 30px;
}
.modalCreateTarget .user-modal--container--close-btn div {
  font-size: 12px;
  margin-top: 4px;
  font-weight: bold;
}
.modalCreateTarget .user-modal--container--buttons {
  display: flex;
  width: 60%;
}
.modalCreateTarget .user-modal--container--checkbox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.sirket-kartlari--inputbox {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sirket-kartlari--inputbox--label {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.23;
  color: #343248;
}
.sirket-kartlari--inputbox--input {
  width: 100%;
  border: none;
  margin: 8px 0 0;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: "Cabin", sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.custom-checkbox.disabled {
  opacity: 0.5;
}
.custom-checkbox input[type=checkbox] {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.custom-checkbox--checkbox {
  display: inline-block;
  height: 16px;
  width: 16px;
  background: #fff;
  border-radius: 4px !important;
  border: 2px #ddd solid;
  cursor: pointer;
}
.custom-checkbox--checkbox.checked {
  border-color: #f9423a;
  background: #f9423a;
}

.quill {
  width: 100%;
}
.quill .ql-toolbar {
  background-color: #fff;
}
.quill .ql-container {
  background-color: #fff;
}

.modalCreateTarget .permission-modal {
  width: 60%;
  min-height: 300px;
  max-width: unset;
  background-color: #f8fbff;
  margin-top: 30px;
}
.modalCreateTarget .permission-modal--container {
  height: 100%;
  padding: 30px 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  gap: 20px;
}
.modalCreateTarget .permission-modal--container .permission-table-wrapper {
  width: 100%;
  max-height: 460px;
  overflow: scroll;
  margin-top: 30px;
}
.modalCreateTarget .permission-modal--container .permission-table-wrapper .permission-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 10px;
  line-height: 35px;
}
.modalCreateTarget .permission-modal--container .permission-table-wrapper .permission-table--head {
  padding: 10px;
  line-height: 35px;
  background-color: rgb(69, 69, 69);
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
  border-radius: 8px;
  margin: 7px 5px;
}
.modalCreateTarget .permission-modal--container .permission-table-wrapper .permission-table--head--row--title {
  color: #fff;
}
.modalCreateTarget .permission-modal--container .permission-table-wrapper .permission-table--head--row--title:first-child {
  padding-left: 10px;
  text-align: start;
}
.modalCreateTarget .permission-modal--container .permission-table-wrapper .permission-table--body {
  max-height: 150px;
  overflow: scroll;
}
.modalCreateTarget .permission-modal--container .permission-table-wrapper .permission-table--body--row {
  margin: 7px 5px;
  border-bottom-width: 0px !important;
  box-shadow: 1px 1px 1px rgb(168, 168, 168);
}
.modalCreateTarget .permission-modal--container .permission-table-wrapper .permission-table--body--row--content:first-child {
  padding-left: 10px;
}
.modalCreateTarget .permission-modal--container--close-btn {
  position: absolute;
  top: 2%;
  right: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.modalCreateTarget .permission-modal--container--close-btn img {
  width: 30px;
}
.modalCreateTarget .permission-modal--container--close-btn div {
  font-size: 12px;
  margin-top: 4px;
  font-weight: bold;
}
.modalCreateTarget .permission-modal--container--buttons {
  display: flex;
  width: 60%;
}
.modalCreateTarget .permission-modal--container--checkbox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.approval-target {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  background-color: #f8fbff;
}
.approval-target--title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.approval-target--list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  margin: auto;
}
.approval-target--list .custom-checkbox {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

.company-management-page {
  max-width: 95%;
  margin: auto;
  position: relative;
}
.company-management-page .rdt_TableBody {
  background-color: #f8fbff;
  padding-right: 10px;
}
.company-management-page .sc-jqUVSM {
  margin: 7px 5px;
  border-bottom-width: 0px !important;
  border-radius: 15px;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
}
.company-management-page .sc-jqUVSM:hover {
  background-color: transparent;
}
.company-management-page .createRowBtn .iconBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px !important;
}

.data-table-extensions > .data-table-extensions-action {
  display: none;
}

.rdt_TableHead .rdt_TableHeadRow {
  background-color: rgb(69, 69, 69);
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
  border-radius: 15px;
  margin: 7px 5px;
}

.rdt_TableHead .rdt_TableCol_Sortable div {
  color: white !important;
}

.not-found-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-found-container--big-title {
  margin-top: 4rem;
  font-size: 16rem;
  font-weight: bolder;
}
.not-found-container--text {
  font-size: 1.5rem;
}
.not-found-container--link {
  width: 150px;
  text-decoration: none;
  margin-top: 2rem;
  text-transform: uppercase;
}
.not-found-container--link--img {
  transform: rotate(180deg);
  margin-right: 5px;
}

.aim-management-page {
  max-width: 95%;
  margin: auto;
  position: relative;
}
.aim-management-page .rdt_TableBody {
  background-color: #f8fbff;
  padding-right: 10px;
}
.aim-management-page .sc-jqUVSM {
  margin: 7px 5px;
  border-bottom-width: 0px !important;
  border-radius: 15px;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
}
.aim-management-page .sc-jqUVSM:hover {
  background-color: transparent;
}
.aim-management-page .createRowBtn .iconBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px !important;
}

.data-table-extensions > .data-table-extensions-action {
  display: none;
}

.rdt_TableHead .rdt_TableHeadRow {
  background-color: rgb(69, 69, 69) !important;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
  border-radius: 15px;
  margin: 7px 5px;
}

.rdt_TableHead .rdt_TableCol_Sortable div {
  color: white !important;
}

.unit-management-page {
  max-width: 95%;
  margin: auto;
  position: relative;
}
.unit-management-page .rdt_TableBody {
  background-color: #f8fbff;
  padding-right: 10px;
}
.unit-management-page .sc-jqUVSM {
  margin: 7px 5px;
  border-bottom-width: 0px !important;
  border-radius: 15px;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
}
.unit-management-page .sc-jqUVSM:hover {
  background-color: transparent;
}
.unit-management-page .createRowBtn .iconBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px !important;
}

.data-table-extensions > .data-table-extensions-action {
  display: none;
}

.rdt_TableHead .rdt_TableHeadRow {
  background-color: rgb(69, 69, 69) !important;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
  border-radius: 15px;
  margin: 7px 5px;
}

.rdt_TableHead .rdt_TableCol_Sortable div {
  color: white !important;
}

.term-management-page {
  max-width: 95%;
  margin: auto;
  position: relative;
}
.term-management-page .rdt_TableBody {
  background-color: #f8fbff;
  padding-right: 10px;
}
.term-management-page .sc-jqUVSM {
  margin: 7px 5px;
  border-bottom-width: 0px !important;
  border-radius: 15px;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
}
.term-management-page .sc-jqUVSM:hover {
  background-color: transparent;
}
.term-management-page .createRowBtn .iconBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px !important;
}

.data-table-extensions > .data-table-extensions-action {
  display: none;
}

.rdt_TableHead .rdt_TableHeadRow {
  background-color: rgb(69, 69, 69) !important;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
  border-radius: 15px;
  margin: 7px 5px;
}

.rdt_TableHead .rdt_TableCol_Sortable div {
  color: white !important;
}

.group-management-page {
  max-width: 95%;
  margin: auto;
  position: relative;
}
.group-management-page .rdt_TableBody {
  background-color: #f8fbff;
  padding-right: 10px;
}
.group-management-page .sc-jqUVSM {
  margin: 7px 5px;
  border-bottom-width: 0px !important;
  border-radius: 15px;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
}
.group-management-page .sc-jqUVSM:hover {
  background-color: transparent;
}
.group-management-page .createRowBtn .iconBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px !important;
}

.data-table-extensions > .data-table-extensions-action {
  display: none;
}

.rdt_TableHead .rdt_TableHeadRow {
  background-color: rgb(69, 69, 69) !important;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
  border-radius: 15px;
  margin: 7px 5px;
}

.rdt_TableHead .rdt_TableCol_Sortable div {
  color: white !important;
}

.create-company-card {
  height: 100%;
}
.create-company-card-header {
  padding: 25px;
  font-weight: bold;
  font-size: 22px;
  color: #1392e5;
  background-color: #ffffff;
}
.create-company-card-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70%;
}
.create-company-card-select #react-dropdown-div-id {
  margin: 25px 10px;
  height: 50px;
  background-color: white;
  padding-top: 5px;
}
.create-company-card-select #react-dropdown-div-id div {
  font-size: 16px;
  font-weight: bold;
}

.company-card-management-page {
  max-width: 95%;
  margin: auto;
  position: relative;
}
.company-card-management-page .rdt_TableBody {
  background-color: #f8fbff;
  padding-right: 10px;
}
.company-card-management-page .sc-jqUVSM {
  margin: 7px 5px;
  border-bottom-width: 0px !important;
  border-radius: 15px;
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
}
.company-card-management-page .sc-jqUVSM:hover {
  background-color: transparent;
}
.company-card-management-page .createRowBtn .iconBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px !important;
}
.company-card-management-page .data-table-extensions {
  width: unset !important;
  margin-top: 10px;
}

.data-table-extensions > .data-table-extensions-action {
  display: none;
}

.TpgDh .rdt_TableHeadRow {
  background-color: rgb(69, 69, 69);
  box-shadow: 1px 1px 5px rgb(168, 168, 168);
  border-radius: 15px;
  margin: 7px 5px;
}

.TpgDh .sc-evZas {
  color: white !important;
}

.SelectStyle {
  margin: 5px;
  display: flex;
  z-Index: 998;
}
.SelectStyle .css-b62m3t-container {
  width: 150px !important;
}
.SelectStyle .css-3iigni-container {
  width: 150px !important;
}
.SelectStyle .css-bfro0a-Control {
  width: unset !important;
}

.SelectContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  z-Index: 2000;
  float: right;
}
.SelectContainer-cardCreate {
  margin-right: 10px;
  margin-left: 7px;
}

.mail-card {
  width: 50%;
}
.mail-card--content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 70px 40px 30px 40px;
  background-color: #f8fbff;
}
.mail-card--content--close-btn {
  position: absolute;
  top: 2%;
  right: 2%;
  cursor: pointer;
}
.mail-card--content--select-area {
  display: flex;
  gap: 20px;
}
.mail-card--content--select-area .css-b62m3t-container {
  width: 90%;
}
.mail-card--content--select-area.h-400 {
  height: 300px;
}
.mail-card--content--button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px !important;
  border: none;
}
.mail-card--content--button:disabled {
  background-color: lightgrey;
  cursor: default;
}
.mail-card--content--button svg polygon,
.mail-card--content--button line {
  color: #ffffff !important;
}

.page-wrapper {
  padding: 32px 24px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.page-wrapper .page-header {
  font-family: "Cabin", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  color: #1392e5;
}
.page-wrapper .welcome-title-area {
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background-color: #f8fbff;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.page-wrapper .welcome-title-area--title {
  height: 24px;
  font-size: 28px;
  font-weight: bold;
  line-height: 0.86;
  color: #343248;
}
.page-wrapper .welcome-title-area--title--red {
  height: 24px;
  font-size: 28px;
  font-weight: bold;
  color: #f9423a;
}
.page-wrapper .welcome-title-area--text {
  height: 24px;
  font-size: 22px;
  line-height: 1.09;
  color: #343248;
}
.page-wrapper .welcome-anouncement-boxes {
  display: flex;
  gap: 24px;
  height: 564px;
  border-radius: 4px;
  flex-wrap: wrap;
}
.page-wrapper .welcome-anouncement-boxes--title {
  height: 24px;
  font-size: 28px;
  font-weight: bold;
  line-height: 0.86;
  color: #343248;
}
.page-wrapper .welcome-anouncement-boxes--large {
  min-width: 65%;
  padding: 24px;
  border-radius: 4px;
  background-color: #f8fbff;
}
@media screen and (max-width: 936px) {
  .page-wrapper .welcome-anouncement-boxes--large {
    min-width: unset;
    width: 100%;
  }
}
.page-wrapper .welcome-anouncement-boxes--small {
  padding: 24px;
  border-radius: 4px;
  flex: 1;
  background-color: #f8fbff;
}

.resources .videos {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.resources .videos--card {
  padding: 20px;
  border-radius: 4px;
  background-color: #ffffff;
}
.resources .videos--card--title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
}
.resources .videos--card--video {
  width: 100%;
  height: 640px;
  border: 1px solid #f1efef;
}/*# sourceMappingURL=styles.css.map */