.reply-message-component{
  border: 1px solid $lightBorder;
  padding: 8px;
  border-radius: 5px;
  max-width:350px;
  width: 100%;
  height: 75px;
  overflow-y: auto;
  box-shadow: 1px 1px 5px $generalGray;
  background-color: $pagesBackColor;
  position: absolute;
  top: 10px;
  margin: 5px;
  cursor: pointer;
    
  
   &-header{
       display: flex;
       align-items: center;
       justify-content: space-between;
       border-bottom: 1px solid $lightBorder;
       padding-bottom: 4px;
       margin-bottom: 7px;

        span{
         font-size: $xsFont; 
        }
        &-icon{
            cursor: pointer;
        }
    }

    &-body{
        
        span{

            strong{
                font-size: $xsFont;
                color: crimson;
            }
        }
    }
}